export default {
    "en": {
        "": "",
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "...": "...",
        "6-Digit code": "6-Digit code",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} image",
        "Aanpassen": "Aanpassen",
        "About us": "About us",
        "Accept Invitation": "Accept Invitation",
        "Access has been requested": "Access has been requested",
        "Account": "Account",
        "Action": "Action",
        "Actions": "Actions",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add": "Add",
        "Add {model}": "Add {model}",
        "Add additional security to your account using two factor authentication.": "Add additional security to your account using two factor authentication.",
        "Add and remove employees in this group": "Add and remove employees in this group",
        "Add a new team member to your team, allowing them to collaborate with you.": "Add a new team member to your team, allowing them to collaborate with you.",
        "Add comment": "Add comment",
        "Add Department": "Add Department",
        "Added.": "Added.",
        "added to group": "added to group",
        "Add employee": "Add employee",
        "Add Employees": "Add Employees",
        "Add group": "Add group",
        "Add location": "Add location",
        "Add Locations": "Add Locations",
        "Add manager": "Add manager",
        "Add menu item": "Add menu item",
        "Add message": "Add message",
        "Add page block": "Add page block",
        "Address": "Address",
        "Address Company": "Address Company",
        "Address Provider": "Address Provider",
        "Add Team Member": "Add Team Member",
        "Add to cart": "Add to cart",
        "Admin Action Notification": "Admin Action Notification",
        "Administrator": "Administrator",
        "Administrator users can perform any action.": "Administrator users can perform any action.",
        "Affiliated employers": "Affiliated employers",
        "After": "After",
        "Age": "Age",
        "Agenda": "Agenda",
        "Agenda item": "Agenda item",
        "Agenda item {startDateTime} - {endTime}": "Agenda item {startDateTime} - {endTime}",
        "Age overview": "Age overview",
        "All": "All",
        "All {attributes}": "All {attributes}",
        "All {models}": "All {models}",
        "All blogs": "All blogs",
        "All of the people that are part of this team.": "All of the people that are part of this team.",
        "Allow cookies": "Allow cookies",
        "Allow indexing": "Allow indexing",
        "All questions": "All questions",
        "All Reviews": "All Reviews",
        "All rights have been removed from your account.": "All rights have been removed from your account.",
        "All rights reserved.": "All rights reserved.",
        "All statuses": "All statuses",
        "Already registered?": "Already registered?",
        "Also of interest?": "Also of interest?",
        "Alt text": "Alt text",
        "A new verification link has been sent to the email address you provided in your profile settings.": "A new verification link has been sent to the email address you provided in your profile settings.",
        "A new verification link has been sent to your email address.": "A new verification link has been sent to your email address.",
        "Answer": "Answer",
        "Answers": "Answers",
        "Answers for {question}": "Answers for {question}",
        "A piece": "A piece",
        "API Token": "API Token",
        "API Token Permissions": "API Token Permissions",
        "API Tokens": "API Tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "API tokens allow third-party services to authenticate with our application on your behalf.",
        "Apply discount code": "Apply discount code",
        "Appointment": "Appointment",
        "appointment": "appointment",
        "Appointment {name}": "Appointment {name}",
        "Appointments": "Appointments",
        "Are you sure you want to cancel this appointment?": "Are you sure you want to cancel this appointment?",
        "Are you sure you want to delete this {model}?": "Are you sure you want to delete this {model}?",
        "Are you sure you want to delete this availability?": "Are you sure you want to delete this availability?",
        "Are you sure you want to delete this department?": "Are you sure you want to delete this department?",
        "Are you sure you want to delete this examination?": "Are you sure you want to delete this examination?",
        "Are you sure you want to delete this group?": "Are you sure you want to delete this group?",
        "Are you sure you want to delete this practitioner?": "Are you sure you want to delete this practitioner?",
        "Are you sure you want to delete this question?": "Are you sure you want to delete this question?",
        "Are you sure you want to delete this reply?": "Are you sure you want to delete this reply?",
        "Are you sure you want to delete this subject?": "Are you sure you want to delete this subject?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.",
        "Are you sure you want to delete this test?": "Are you sure you want to delete this test?",
        "Are you sure you want to delete this test metric?": "Are you sure you want to delete this test metric?",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.",
        "Are you sure you want to remove this employee?": "Are you sure you want to remove this employee?",
        "Are you sure you would like to delete this API token?": "Are you sure you would like to delete this API token?",
        "Are you sure you would like to leave this team?": "Are you sure you would like to leave this team?",
        "Are you sure you would like to remove this person from the team?": "Are you sure you would like to remove this person from the team?",
        "Ascension day": "Ascension day",
        "Attachment": "Attachment",
        "Author": "Author",
        "Automation": "Automation",
        "automation": "Automation",
        "Availability": "Availability",
        "availability": "availability",
        "Available specialists": "Available specialists",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Backup codes": "Backup codes",
        "Banner": "Banner",
        "Before": "Before",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.",
        "Belongs to the questionnaire {testName}": "Belongs to the questionnaire {testName}",
        "Belongs to the test {testName}": "Belongs to the test {testName}",
        "BIG registration number": "BIG registration number",
        "Billing Address": "Billing Address",
        "Billing address": "Billing address",
        "Birth date": "Birth date",
        "Block day": "Block day",
        "Blog": "Blog",
        "Blog Settings": "Blog Settings",
        "bloodtest": "bloodtest",
        "Blood Test Overview": "Blood Test Overview",
        "Blood Tests": "Blood Tests",
        "Blue Button": "Blue Button",
        "Browser Sessions": "Browser Sessions",
        "BTW number": "BTW number",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Buttons": "Buttons",
        "Cancel": "Cancel",
        "Cancel appointment": "Cancel appointment",
        "cancelled": "cancelled",
        "Categories": "Categories",
        "Category": "Category",
        "Category Settings": "Category Settings",
        "Changes": "Changes",
        "Change Video": "Change Video",
        "Checkbox": "Checkbox",
        "Checkout page": "Checkout page",
        "City": "City",
        "Click here to re-send the verification email.": "Click here to re-send the verification email.",
        "Client": "Client",
        "Client name": "Client name",
        "Clients": "Clients",
        "Close": "Close",
        "Close conversation": "Close conversation",
        "closed": "closed",
        "Code": "Code",
        "code": "code",
        "Comment": "Comment",
        "Companies": "Companies",
        "Company": "Company",
        "company": "company",
        "Company Administrator": "Company Administrator",
        "Company name": "Company name",
        "Company Subscription": "Company Subscription",
        "company subscription": "company subscription",
        "Complete": "Complete",
        "Completed tasks": "Completed tasks",
        "Completed tasks overview": "Completed tasks overview",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conditional Page link": "Conditional Page link",
        "Confirm": "Confirm",
        "Confirm Password": "Confirm Password",
        "Confirm password": "Confirm password",
        "Consultant": "Consultant",
        "Consultation": "Consultation",
        "Consultations": "Consultations",
        "Contact": "Contact",
        "Contact persoons": "Contact persoons",
        "Content": "Content",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Copy from": "Copy from",
        "Coupon": "Coupon",
        "Coupons": "Coupons",
        "Create": "Create",
        "Create {model}": "Create {model}",
        "Create Account": "Create Account",
        "Create and back": "Create and back",
        "Create a new team to collaborate with others on projects.": "Create a new team to collaborate with others on projects.",
        "Create API Token": "Create API Token",
        "Create availability": "Create availability",
        "Create consultation": "Create consultation",
        "created": "created",
        "Created.": "Created.",
        "Created at": "Created at",
        "Create follow-up": "Create follow-up",
        "Create New Team": "Create New Team",
        "Create order": "Create order",
        "Create question": "Create question",
        "Create questionnaire": "Create questionnaire",
        "Create referral": "Create referral",
        "Create reply": "Create reply",
        "Create subject": "Create subject",
        "Create Team": "Create Team",
        "currency": "currency",
        "Current Password": "Current Password",
        "Current password": "Current password",
        "Customers": "Customers",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low": "Dangerously low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Dashboard": "Dashboard",
        "Date": "Date",
        "Date of birth": "Date of birth",
        "Day": "Day",
        "Deactivate": "Deactivate",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Dear user": "Dear user",
        "Default consultant": "Default consultant",
        "Delete": "Delete",
        "Delete {model}": "Delete {model}",
        "Delete {name}": "Delete {name}",
        "Delete Account": "Delete Account",
        "Delete API Token": "Delete API Token",
        "Delete availability": "Delete availability",
        "Delete comment": "Delete comment",
        "deleted": "deleted",
        "Delete examination": "Delete examination",
        "Delete group": "Delete group",
        "Delete question": "Delete question",
        "Delete reply": "Delete reply",
        "Delete subject": "Delete subject",
        "Delete Team": "Delete Team",
        "Delete test": "Delete test",
        "Delete test metric": "Delete test metric",
        "Department": "Department",
        "department": "department",
        "Departments": "Departments",
        "Description": "Description",
        "description": "description",
        "Desktop": "Desktop",
        "Details": "Details",
        "Director": "Director",
        "Disable": "Disable",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "done": "done",
        "Done.": "Done.",
        "Don’t have access to your authenticator device ?": "Don’t have access to your authenticator device ?",
        "Down": "Down",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download invoice": "Download invoice",
        "Download the app": "Download the app",
        "Due at": "Due at",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Easter monday": "Easter monday",
        "Easter sunday": "Easter sunday",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit account": "Edit account",
        "Edit client": "Edit client",
        "Edit Group": "Edit Group",
        "Editor": "Editor",
        "Editor users have the ability to read, create, and update.": "Editor users have the ability to read, create, and update.",
        "Edit Profile": "Edit Profile",
        "Edit user": "Edit user",
        "Email": "Email",
        "email": "email",
        "email address": "email address",
        "Email Password Reset Link": "Email Password Reset Link",
        "Employee": "Employee",
        "Employee is part of every group": "Employee is part of every group",
        "Employee number": "Employee number",
        "Employees": "Employees",
        "Enable": "Enable",
        "End": "End",
        "End at": "End at",
        "End time": "End time",
        "Ensure your account is using a long, random password to stay secure.": "Ensure your account is using a long, random password to stay secure.",
        "Enter your e-mail and we send you a reset link": "Enter your e-mail and we send you a reset link",
        "Examination": "Examination",
        "examination": "examination",
        "Examination {name}": "Examination {name}",
        "Examinations": "Examinations",
        "Excel template": "Excel template",
        "Excel update": "Excel update",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Execute all changes": "Execute all changes",
        "Export shipping addresses": "Export shipping addresses",
        "External Link": "External Link",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "Featured Image": "Featured Image",
        "File": "File",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Fill in this code in the app.": "Fill in this code in the app.",
        "Finish enabling two factor authentication.": "Finish enabling two factor authentication.",
        "First, select a department": "First, select a department",
        "First, select a practice": "First, select a practice",
        "First, select a speciality": "First, select a speciality",
        "First name": "First name",
        "Fixed": "Fixed",
        "Focal point": "Focal point",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Forbidden": "Forbidden",
        "Forgotten password?": "Forgotten password?",
        "Forgot your password?": "Forgot your password?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.",
        "For your security, please confirm your password to continue.": "For your security, please confirm your password to continue.",
        "Free": "Free",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "Friday": "Friday",
        "From the authenticator app": "From the authenticator app",
        "Gallery": "Gallery",
        "Geen rol": "Geen rol",
        "Gender": "Gender",
        "Gender overview": "Gender overview",
        "General terms": "General terms",
        "Good friday": "Good friday",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "Great! You have accepted the invitation to join the {team} team.": "Great! You have accepted the invitation to join the {team} team.",
        "Group": "Group",
        "Group {name}": "Group {name}",
        "Groups": "Groups",
        "Group subdivision": "Group subdivision",
        "Has a group": "Has a group",
        "Heading": "Heading",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Hide on page": "Hide on page",
        "High": "High",
        "High explanation": "High explanation",
        "Highlighted Questions": "Highlighted Questions",
        "Hits": "Hits",
        "How much wood could a woodchuck chuck, if a woodchuck could chuck wood?": "How much wood could a woodchuck chuck, if a woodchuck could chuck wood?",
        "I agree to the {terms_of_service} and {privacy_policy}": "I agree to the {terms_of_service} and {privacy_policy}",
        "Icon": "Icon",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If left empty, the page title will be used.": "If left empty, the page title will be used.",
        "If left empty, the title will be used.": "If left empty, the title will be used.",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you already have an account, you may accept this invitation by clicking the button below:": "If you already have an account, you may accept this invitation by clicking the button below:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "If you did not expect to receive an invitation to this team, you may discard this email.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:",
        "If you wish to use our services again, you can create a new account. Unfortunately, you cannot view the results of deleted accounts with a new account.": "If you wish to use our services again, you can create a new account. Unfortunately, you cannot view the results of deleted accounts with a new account.",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Information": "Information",
        "Inloggen": "Inloggen",
        "Input label": "Input label",
        "Inspection": "Inspection",
        "inspection": "Inspection",
        "Inspections": "Inspections",
        "Instruction title": "Instruction title",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Introduction": "Introduction",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invalid phone number": "Invalid phone number",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "KVK number": "KVK number",
        "Label": "Label",
        "Label Button": "Label Button",
        "Laboratory": "Laboratory",
        "Last active": "Last active",
        "Last message on": "Last message on",
        "Last name": "Last name",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Last used": "Last used",
        "Leave": "Leave",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Leave Team": "Leave Team",
        "Link": "Link",
        "Link {model}": "Link {model}",
        "Link Button": "Link Button",
        "Link inspection to other examination": "Link inspection to other examination",
        "List": "List",
        "Location": "Location",
        "location": "location",
        "Locations": "Locations",
        "Log in": "Log in",
        "Login": "Login",
        "Login with a recovery code.": "Login with a recovery code.",
        "Log Out": "Log Out",
        "Logout": "Logout",
        "Log Out Other Browser Sessions": "Log Out Other Browser Sessions",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Manage Account": "Manage Account",
        "Manage and log out your active sessions on other browsers and devices.": "Manage and log out your active sessions on other browsers and devices.",
        "Manage API Tokens": "Manage API Tokens",
        "Manager": "Manager",
        "Manage Role": "Manage Role",
        "Managers": "Managers",
        "Manage Team": "Manage Team",
        "Manage the company subscriptions": "Manage the company subscriptions",
        "Manual intervention is needed": "Manual intervention is needed",
        "Mark as done": "Mark as done",
        "Max users": "Max users",
        "Media": "Media",
        "Medical": "Medical",
        "Menu items": "Menu items",
        "Message": "Message",
        "Messages": "Messages",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimal order amount",
        "Mobile": "Mobile",
        "Monday": "Monday",
        "More information": "More information",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "My groups": "My groups",
        "Name": "Name",
        "name": "name",
        "Name client": "Name client",
        "New consultation": "New consultation",
        "New Password": "New Password",
        "New password": "New password",
        "New question": "New question",
        "New reply": "New reply",
        "New subject": "New subject",
        "New URL": "New URL",
        "Next": "Next",
        "No": "No",
        "No {models} left to add": "No {models} left to add",
        "No data available": "No data available",
        "No items": "No items",
        "No location": "No location",
        "No manager": "No manager",
        "None": "None",
        "No open conversations, a new conversation will be created": "No open conversations, a new conversation will be created",
        "No other employees available": "No other employees available",
        "No other subscriptions available": "No other subscriptions available",
        "No other suitable examinations": "No other suitable examinations",
        "No parent group": "No parent group",
        "No practitioners left to add": "No practitioners left to add",
        "No results": "No results",
        "Normal": "Normal",
        "Normal explanation": "Normal explanation",
        "No subscription left to add": "No subscription left to add",
        "Not editable after creation.": "Not editable after creation.",
        "Not Found": "Not Found",
        "Not implemented yet": "Not implemented yet",
        "Number": "Number",
        "Number of customers": "Number of customers",
        "Number of questions": "Number of questions",
        "Number of specialists": "Number of specialists",
        "Number of test": "Number of test",
        "of": "of",
        "Old URL": "Old URL",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.",
        "Onderwerpen": "Onderwerpen",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Openings hours": "Openings hours",
        "Open in new tab": "Open in new tab",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "or": "or",
        "Orange": "Orange",
        "Orange Button": "Orange Button",
        "Order": "Order",
        "Orders": "Orders",
        "Overview": "Overview",
        "Page": "Page",
        "Page blocks": "Page blocks",
        "Page Content": "Page Content",
        "Page Expired": "Page Expired",
        "Page link": "Page link",
        "Pages": "Pages",
        "Page Title": "Page Title",
        "Pagination Navigation": "Pagination Navigation",
        "Paragraph": "Paragraph",
        "Parent category": "Parent category",
        "Parent group": "Parent group",
        "Password": "Password",
        "password": "password",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payment Required": "Payment Required",
        "Pay safe": "Pay safe",
        "Pending Team Invitations": "Pending Team Invitations",
        "Pentecost": "Pentecost",
        "Pentecost monday": "Pentecost monday",
        "Percentage": "Percentage",
        "Permanent": "Permanent",
        "Permanently delete this team.": "Permanently delete this team.",
        "Permanently delete your account.": "Permanently delete your account.",
        "Permissions": "Permissions",
        "Personal accounts with subscriptions": "Personal accounts with subscriptions",
        "Phone": "Phone",
        "Phone Number": "Phone Number",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "phone_number": "phone_number",
        "Photo": "Photo",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Please confirm access to your account by entering one of your emergency recovery codes.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Please confirm access to your account by entering the authentication code provided by your authenticator application.",
        "Please copy your new API token. For your security, it won't be shown again.": "Please copy your new API token. For your security, it won't be shown again.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.",
        "Please provide the email address of the person you would like to add to this team.": "Please provide the email address of the person you would like to add to this team.",
        "Please submit manual changes individually": "Please submit manual changes individually",
        "Please use the mobile app. This web app is for staff only": "Please use the mobile app. This web app is for staff only",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Practice": "Practice",
        "practice": "practice",
        "Practices": "Practices",
        "Practitioner": "Practitioner",
        "practitioner": "practitioner",
        "Price": "Price",
        "Print": "Print",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Privacy page": "Privacy page",
        "Privacy Policy": "Privacy Policy",
        "Privacy policy": "Privacy policy",
        "Privacy statement": "Privacy statement",
        "Product": "Product",
        "Products": "Products",
        "Product Settings": "Product Settings",
        "Profile": "Profile",
        "Profile Information": "Profile Information",
        "Provider": "Provider",
        "provider": "provider",
        "Providers": "Providers",
        "Published At": "Published At",
        "Purple": "Purple",
        "QR code": "QR code",
        "Quantity": "Quantity",
        "Question": "Question",
        "Questionnaire": "Questionnaire",
        "questionnaire": "questionnaire",
        "Questionnaire Overview": "Questionnaire Overview",
        "Questionnaires": "Questionnaires",
        "Questions": "Questions",
        "Questions for {subjectName}": "Questions for {subjectName}",
        "Questions using {testMetricName}": "Questions using {testMetricName}",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Realized by:": "Realized by:",
        "Reason for cancellation": "Reason for cancellation",
        "Recovery Code": "Recovery Code",
        "Referral": "Referral",
        "referral": "referral",
        "Referral letter": "Referral letter",
        "Referrals": "Referrals",
        "Regards": "Regards",
        "Regenerate Recovery Codes": "Regenerate Recovery Codes",
        "Register": "Register",
        "Reimbursable treatments": "Reimbursable treatments",
        "Reject all changes": "Reject all changes",
        "Reject cookies": "Reject cookies",
        "Related blog": "Related blog",
        "Related products": "Related products",
        "Relink": "Relink",
        "Remember me": "Remember me",
        "Remove": "Remove",
        "removed from group": "removed from group",
        "Remove discount code": "Remove discount code",
        "Remove employee": "Remove employee",
        "Remove Photo": "Remove Photo",
        "Remove Team Member": "Remove Team Member",
        "Reopened subtask": "Reopened subtask",
        "Reply": "Reply",
        "Request access to client information": "Request access to client information",
        "Request report": "Request report",
        "Resend": "Resend",
        "Resend {attribute}": "Resend {attribute}",
        "Resend in": "Resend in",
        "Resend Verification Email": "Resend Verification Email",
        "Reserved By": "Reserved By",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Results": "results",
        "results": "results",
        "Results {testName} are shared": "Results {testName} are shared",
        "Results {testName} are shared by {name}": "Results {testName} are shared by {name}",
        "Results {testName} shared by {name}": "Results {testName} shared by {name}",
        "Results {testName} where shared by {name}": "Results {testName} where shared by {name}",
        "Results are shared": "Results are shared",
        "Reviews": "Reviews",
        "Rol": "Rol",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Sampled at": "Sampled at",
        "Saturday": "Saturday",
        "Save": "Save",
        "Save and back": "Save and back",
        "Saved.": "Saved.",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scanning not possible?": "Scanning not possible?",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Scope": "Scope",
        "Score": "Score",
        "Search": "Search",
        "Search by {attribute}": "Search by {attribute}",
        "Search by client": "Search by client",
        "Search for your question": "Search for your question",
        "sec": "sec",
        "Select a {model}": "Select a {model}",
        "Select a {model} to add them": "Select a {model} to add them",
        "Select a client": "Select a client",
        "Select a conversation to add": "Select a conversation to add",
        "Select a department": "Select a department",
        "Select a employee": "Select a employee",
        "Select an consultant": "Select an consultant",
        "Select A New Photo": "Select A New Photo",
        "Select an practitioner": "Select an practitioner",
        "Select an practitioner to add them": "Select an practitioner to add them",
        "Select a practice": "Select a practice",
        "Select a provider": "Select a provider",
        "Select a questionnaire to use as a template": "Select a questionnaire to use as a template",
        "Select a role": "Select a role",
        "Select a speciality": "Select a speciality",
        "Select a status": "Select a status",
        "Select a subject": "Select a subject",
        "Select a subscription": "Select a subscription",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a test metric": "Select a test metric",
        "Select a time slot": "Select a time slot",
        "Select a type": "Select a type",
        "Select company": "Select company",
        "Select examination": "Select examination",
        "Select group to add": "Select group to add",
        "Select subscription": "Select subscription",
        "Send": "Send",
        "Send e-mail": "Send e-mail",
        "Send verification {attribute}": "Send verification {attribute}",
        "Seo Settings": "Seo Settings",
        "SEO title": "SEO title",
        "Sequence number": "Sequence number",
        "sequence number": "Sequence number",
        "serial number": "serial number",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Set  password": "Set  password",
        "Sets": "Sets",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Setup Key": "Setup Key",
        "Set your password": "Set your password",
        "Set your secure password below": "Set your secure password below",
        "Share {testName} results": "Share {testName} results",
        "Share blog": "Share blog",
        "shared": "shared",
        "Share results": "Share results",
        "Shipping address": "Shipping address",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping method": "Shipping method",
        "Shipping Rate": "Shipping Rate",
        "Shipping Rates": "Shipping Rates",
        "Shop": "Shop",
        "Shopping cart page": "Shopping cart page",
        "Should you have any questions, please contact us at": "Should you have any questions, please contact us at",
        "Showing": "Showing",
        "Show Recovery Codes": "Show Recovery Codes",
        "Sign out": "Sign out",
        "Similar inspections on the target examination will be deleted": "Similar inspections on the target examination will be deleted",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.",
        "Slug": "Slug",
        "Specialities": "Specialities",
        "Speciality": "Speciality",
        "speciality": "speciality",
        "Specialty": "Specialty",
        "Start": "Start",
        "Start conversation": "Start conversation",
        "Started at": "Started at",
        "Starting": "Starting",
        "Starting after": "Starting after",
        "Starting from": "Starting from",
        "Starts at": "Starts at",
        "Start time": "Start time",
        "Status": "Status",
        "Statuses": "Statuses",
        "statuses": "Statuses",
        "Step": "Step",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Strixi": "Strixi",
        "Subject": "Subject",
        "Subjects of {model}": "Subjects of {model}",
        "Sub menu": "Sub menu",
        "Subscription": "Subscription",
        "subscription": "subscription",
        "Subscriptions": "Subscriptions",
        "SubTasks": "SubTasks",
        "Subtotal": "Subtotal",
        "Sunday": "Sunday",
        "Switch Teams": "Switch Teams",
        "Switch to named day": "Switch to named day",
        "Switch to specific date": "Switch to specific date",
        "Task": "Task",
        "Tasks": "Tasks",
        "Team Details": "Team Details",
        "Team Invitation": "Team Invitation",
        "Team Members": "Team Members",
        "Team Name": "Team Name",
        "Team Owner": "Team Owner",
        "Team Settings": "Team Settings",
        "Terms and Conditions": "Terms and Conditions",
        "Terms of Service": "Terms of Service",
        "Terms page": "Terms page",
        "Test": "Test",
        "test": "test",
        "Test metric": "Test metric",
        "Test metric of {model}": "Test metric of {model}",
        "Test metrics": "Test metrics",
        "Test name": "Test name",
        "Tests": "Tests",
        "Test Set": "Test Set",
        "Test set": "Test set",
        "test set": "test set",
        "Testset name": "Testset name",
        "Test sets": "Test sets",
        "Tests taken": "Tests taken",
        "Text": "Text",
        "Thank you! We will keep you informed!": "Thank you! We will keep you informed!",
        "Thank you for your message.": "Thank you for your message.",
        "Thank you page settings": "Thank you page settings",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} must be at least {length} characters.": "The {attribute} must be at least {length} characters.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "The {attribute} must be at least {length} characters and contain at least one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "The {attribute} must be at least {length} characters and contain at least one special character.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "The {attribute} must be at least {length} characters and contain at least one special character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.",
        "The {attribute} must be a valid role.": "The {attribute} must be a valid role.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The accounts which have access to this company": "The accounts which have access to this company",
        "The admin {name} has viewed the medical results in task {number}.": "The admin {name} has viewed the medical results in task {number}.",
        "The conversation is closed.": "The conversation is closed.",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The file is missing the \"{header}\" column": "The file is missing the \"{header}\" column",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The lab results for the inspection with barcode {barcode} (test: {test}) needs manual intervention.": "The lab results for the inspection with barcode {barcode} (test: {test}) needs manual intervention.",
        "The message from the lab is: {message}": "The message from the lab is: {message}",
        "The password is incorrect.": "The password is incorrect.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided password was incorrect.": "The provided password was incorrect.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The provided two factor recovery code was invalid.": "The provided two factor recovery code was invalid.",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.",
        "The team's name and owner information.": "The team's name and owner information.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This area is restricted to company members only.": "This area is restricted to company members only.",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to provider members only.": "This area is restricted to provider members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This device": "This device",
        "This is a secure area of the application. Please confirm your password before continuing.": "This is a secure area of the application. Please confirm your password before continuing.",
        "This is the label that will be shown to the user when they are asked to input a value.": "This is the label that will be shown to the user when they are asked to input a value.",
        "This is your first login": "This is your first login",
        "This password does not match our records.": "This password does not match our records.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "This user already belongs to the team.": "This user already belongs to the team.",
        "This user has already been invited to the team.": "This user has already been invited to the team.",
        "Thursday": "Thursday",
        "Time": "Time",
        "Time reference": "Time reference",
        "Time slot": "Time slot",
        "Time slot {startDateTime} - {endTime}": "Time slot {startDateTime} - {endTime}",
        "Time slots": "Time slots",
        "Times used": "Times used",
        "Tip": "Tip",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "TODO": "TODO",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Token Name": "Token Name",
        "Too Many Requests": "Too Many Requests",
        "Total": "Total",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now",
        "Trans": "Trans",
        "Tuesday": "Tuesday",
        "Two-factor Confirmation": "Two-factor Confirmation",
        "Two Factor Authentication": "Two Factor Authentication",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is required as extra security, you can set this now",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "Unknown": "Unknown",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Up": "Up",
        "Update": "Update",
        "Update {model}": "Update {model}",
        "Update and back": "Update and back",
        "Update comment": "Update comment",
        "Update consultation": "Update consultation",
        "updated": "updated",
        "Update Password": "Update Password",
        "Update password": "Update password",
        "Update your account's profile information and email address.": "Update your account's profile information and email address.",
        "Upload": "Upload",
        "upload": "upload",
        "Url": "Url",
        "url": "url",
        "Use an authentication code": "Use an authentication code",
        "Use a recovery code": "Use a recovery code",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Value": "Value",
        "Variant": "Variant",
        "Variants": "Variants",
        "VAT": "VAT",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "Verify your phone number": "Verify your phone number",
        "Verwijderen": "Verwijderen",
        "View": "View",
        "Waiting for payment conformation": "Waiting for payment conformation",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Wednesday": "Wednesday",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We hereby confirm that your account has been deleted from our platform. This is also the last email you will receive from us.": "We hereby confirm that your account has been deleted from our platform. This is also the last email you will receive from us.",
        "Welcome": "Welcome",
        "Welcome to {page}": "Welcome to {page}",
        "We were unable to find a registered user with this email address.": "We were unable to find a registered user with this email address.",
        "When assigning a qr code already in use, the previous test set will be unassigned.": "When assigning a qr code already in use, the previous test set will be unassigned.",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.",
        "Whoops!": "Whoops!",
        "Whoops! Something went wrong.": "Whoops! Something went wrong.",
        "Yes": "Yes",
        "You are muted.": "You are muted.",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You have been invited to join the {team} team!": "You have been invited to join the {team} team!",
        "You have enabled two factor authentication.": "You have enabled two factor authentication.",
        "You have not enabled two factor authentication.": "You have not enabled two factor authentication.",
        "You may accept this invitation by clicking the button below:": "You may accept this invitation by clicking the button below:",
        "You may delete any of your existing tokens if they are no longer needed.": "You may delete any of your existing tokens if they are no longer needed.",
        "You may not delete your personal team.": "You may not delete your personal team.",
        "You may not leave a team that you created.": "You may not leave a team that you created.",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your email address is unverified.": "Your email address is unverified.",
        "Your experience on this site is enhanced by the use of cookies.": "Your experience on this site is enhanced by the use of cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your password has expired, please reset your password.": "Your password has expired, please reset your password.",
        "Your password has expired. Please reset your password to continue.": "Your password has expired. Please reset your password to continue.",
        "Your Profile": "Your Profile",
        "Your shopping cart is empty": "Your shopping cart is empty",
        "Your verification code for {appName}.": "Your verification code for {appName}.",
        "Your verification code for {appName} is {code}.": "Your verification code for {appName} is {code}.",
        "auth": {
            "failed": "These credentials do not match our records or the credentials are expired.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "routes": {
            "products": "products",
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field is required.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "billing_address.postcode": "postcode",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "consultant_id": "consultant",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "role_id": "role",
                "second": "second",
                "sex": "sex",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "shipping_address.postcode": "postcode",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_rate_id": "shipping rate",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year",
                "test_ids": "tests",
                "test_ids.*": "test",
                "product_id": "product",
                "test_set_id": "test set"
            }
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmeldingen)",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "...": "...",
        "6-Digit code": "6-cijferige code",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is ongeldig",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} afbeelding",
        "Aanpassen": "Aanpassen",
        "About HGBI": "Over HGBI",
        "About us": "Over ons",
        "Accept Invitation": "Uitnodiging accepteren",
        "Access has been requested": "Toegaan aangevraagd",
        "Account": "Account",
        "Started": "Gestart",
        "Action": "Actie",
        "Waiting for consultation": "Wachten op consultatie",
        "Actions": "Acties",
        "Activated": "Geactiveerd",
        "activated": "geactiveerd",
        "Active": "Actief",
        "Add": "Toevoegen",
        "Needs manual intervention": "Handmatige interventie nodig",
        "Add {model}": "{model} toevoegen",
        "Add additional security to your account using two factor authentication.": "Voeg extra beveiliging toe aan je account met tweestapsverificatie.",
        "Add and remove employees in this group": "Werknemers in deze groep toevoegen en verwijderen",
        "Add a new team": "Een nieuw team toevoegen",
        "Add a new team member to your team, allowing them to collaborate with you.": "Voeg een nieuw teamlid toe aan je team, zodat ze met je kunnen samenwerken.",
        "Add comment": "Opmerking toevoegen",
        "Add Department": "Afdeling toevoegen",
        "Added.": "Toegevoegd.",
        "added to group": "toegevoegd aan groep",
        "Add employee": "Werknemer toevoegen",
        "Add Employees": "Werknemers toevoegen",
        "Add group": "Groep toevoegen",
        "Add location": "Locatie toevoegen",
        "Add Locations": "Locaties toevoegen",
        "Add manager": "Manager toevoegen",
        "Add menu item": "Menu-item toevoegen",
        "Add message": "Bericht toevoegen",
        "Add page block": "Pagina blok toevoegen",
        "Address": "Adres",
        "Address Company": "Adres Bedrijf",
        "Address Provider": "Adres Zorgverlener",
        "Add Team Member": "Teamlid toevoegen",
        "Add to cart": "In winkelwagen",
        "Admin Action Notification": "Administratieve actiemelding",
        "Administrator": "Beheerder",
        "Administrator users can perform any action.": "Beheerders kunnen elke actie uitvoeren.",
        "Affiliated employers": "Aangesloten werkgevers",
        "After": "Na",
        "Age": "Leeftijd",
        "Agenda": "Agenda",
        "Agenda item": "Agendapunt",
        "Agenda item {startDateTime} - {endTime}": "Agenda-item {startDateTime} - {endTime}",
        "Age overview": "Leeftijdsoverzicht",
        "All": "Alle",
        "All {attributes}": "Alle {attributes}",
        "All {models}": "Alle {modellen}",
        "All blogs": "Alle blogs",
        "All of the people that are part of this team.": "Alle mensen die deel uitmaken van dit team.",
        "Allow cookies": "Cookies toestaan",
        "Allow indexing": "Indexering toestaan",
        "All questions": "Alle vragen",
        "All Reviews": "Alle beoordelingen",
        "All rights have been removed from your account.": "Alle rechten zijn verwijderd uit je account.",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "All statuses": "Alle statussen",
        "Already registered?": "Al geregistreerd?",
        "Also of interest?": "Ook interessant?",
        "Alt text": "Alt-tekst",
        "A new verification link has been sent to the email address you provided in your profile settings.": "Er is een nieuwe verificatielink verstuurd naar het e-mailadres dat je ingegeven hebt in je profielinstellingen.",
        "A new verification link has been sent to your email address.": "Er is een nieuwe verificatielink naar je e-mailadres verstuurd.",
        "Answer": "Antwoord",
        "Answers": "Antwoorden",
        "Answers for {question}": "Antwoorden voor {question}",
        "any": "enkele",
        "A piece": "Per stuk",
        "API Token": "API-token",
        "API Token Permissions": "API-tokenrechten",
        "API Tokens": "API-tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "Met API-tokens kunnen andere services zich als jou authenticeren in onze applicatie.",
        "Apply discount code": "Kortingscode toepassen",
        "Appointment": "Afspraak",
        "appointment": "afspraak",
        "Appointment {name}": "Afspraak {name}",
        "Appointments": "Afspraken",
        "Approved": "Goedgekeurd",
        "Are you sure you want to cancel this appointment?": "Weet u zeker dat u deze afspraak wilt annuleren?",
        "Are you sure you want to delete this {model}?": "Weet u zeker dat u dit {model} wilt verwijderen?",
        "Are you sure you want to delete this availability?": "Weet je zeker dat je deze beschikbaarheid wilt verwijderen?",
        "Are you sure you want to delete this department?": "Weet je zeker dat je deze afdeling wilt verwijderen?",
        "Are you sure you want to delete this examination?": "Weet je zeker dat je dit onderzoek wilt verwijderen?",
        "Are you sure you want to delete this group?": "Weet je zeker dat je deze groep wilt verwijderen?",
        "Are you sure you want to delete this practitioner?": "Weet je zeker dat je deze medewerker wilt verwijderen?",
        "Are you sure you want to delete this question?": "Weet je zeker dat je deze vraag wilt verwijderen?",
        "Are you sure you want to delete this reply?": "Weet je zeker dat je dit antwoord wilt verwijderen?",
        "Are you sure you want to delete this subject?": "Weet je zeker dat je dit onderwerp wilt verwijderen?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Weet je zeker dat je dit team wilt verwijderen? Zodra een team is verwijderd, worden alle bronnen en gegevens ook permanent verwijderd.",
        "Are you sure you want to delete this test?": "Weet je zeker dat je deze test wilt verwijderen?",
        "Are you sure you want to delete this test metric?": "Weet je zeker dat je deze testmetriek wilt verwijderen?",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Weet je zeker dat je je account permanent wilt verwijderen? Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Voer alsjeblieft je wachtwoord in, om te bevestigen dat je je account permanent wilt verwijderen.",
        "Are you sure you want to remove this employee?": "Weet je zeker dat je deze medewerker wilt verwijderen?",
        "Are you sure you would like to delete this API token?": "Weet je zeker dat je deze API-token wilt verwijderen?",
        "Are you sure you would like to leave this team?": "Weet je zeker dat je dit team wilt verlaten?",
        "Are you sure you would like to remove this person from the team?": "Weet je zeker dat je deze persoon uit het team wilt verwijderen?",
        "As a new team member, you play a crucial role in our mission to enhance the vitality and well-being of businesses and their employees. At Hoegezondbenik.nu, we value innovation, collaboration, and the personal growth of our staff.": "Als nieuw teamlid speelt u een cruciale rol in onze missie om de vitaliteit en het welzijn van bedrijven en hun werknemers te verbeteren. Bij Hoegezondbenik.nu waarderen we innovatie, samenwerking en de persoonlijke groei van ons personeel.",
        "Ascension day": "Hemelvaartsdag",
        "At {link}, we pride ourselves on providing tailored solutions to meet the unique health and wellness needs. Whether it's through our comprehensive health checks, expert consultations, or innovative well-being strategies, we are here to support you every step of the way.": "Bij {link} zijn we trots op het bieden van op maat gemaakte oplossingen om te voldoen aan de unieke gezondheids- en welzijnsbehoeften. Of het nu gaat om onze uitgebreide gezondheidscontroles, deskundige consultaties of innovatieve welzijnsstrategieën, wij zijn er om u bij elke stap te ondersteunen.",
        "Attachment": "Bijlage",
        "Author": "Auteur",
        "Automation": "Automatisering",
        "automation": "Automatisering",
        "Availability": "Beschikbaarheid",
        "availability": "beschikbaarheid",
        "Available": "Beschikbaar",
        "Available specialists": "Beschikbare specialisten",
        "Back": "Terug",
        "Back and mark as done": "Terug en markeer als klaar",
        "Back and release": "Terug en loslaten",
        "Back to home": "Terug naar home",
        "Backup codes": "Back-up codes",
        "Banner": "Banner",
        "Before": "Voor",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Kun je voor je verder gaat je e-mailadres verifiëren door op de link te klikken die we net naar je verstuurd hebben? Als je geen e-mail hebt ontvangen, sturen wij je er graag nog een.",
        "Belongs to the questionnaire {testName}": "Behoort tot de vragenlijst {testName}",
        "Belongs to the test {testName}": "Behoort tot de test {testName}",
        "BIG registration number": "BIG registratienummer",
        "Billing Address": "Factuuradres",
        "Billing address": "Factuuradres",
        "Birth date": "Geboortedatum",
        "Block day": "Blockeer dag",
        "Blocked": "Geblokkeerd",
        "Blog": "Blog",
        "Blog Settings": "Blog Instellingen",
        "bloodtest": "bloedtest",
        "Blood Test Overview": "Overzicht bloedtest",
        "Blood Tests": "Bloedtests",
        "Blue Button": "Blauwe knop",
        "Browser Sessions": "Browsersessies",
        "BTW number": "BTW-nummer",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Buttons": "Knoppen",
        "Cancel": "Annuleren",
        "Cancel appointment": "Afspraak annuleren",
        "Cancelled": "Geannuleerd",
        "cancelled": "geannuleerd",
        "Categories": "Categorieën",
        "Category": "Categorie",
        "Category Settings": "Categorie Instellingen",
        "Changes": "Veranderingen",
        "Change Video": "Video veranderen",
        "Checkbox": "Checkbox",
        "Checkout page": "Afrekenpagina",
        "City": "Stad",
        "Click here to re-send the verification email.": "Klik hier om de verificatie e-mail opnieuw te versturen.",
        "Client": "Cliënt",
        "Client name": "Naam klant",
        "Clients": "Cliënten",
        "Close": "Sluit",
        "Close conversation": "Sluit gesprek",
        "closed": "gesloten",
        "Code": "Code",
        "code": "code",
        "Comment": "Commentaar",
        "Companies": "Bedrijven",
        "Company": "Bedrijf",
        "company": "bedrijf",
        "Company Administrator": "Bedrijfsadministrateur",
        "Company name": "Bedrijfsnaam",
        "Company Subscription": "Bedrijfsabonnement",
        "company subscription": "bedrijfsabonnement",
        "Complete": "Afronden",
        "Completed": "Voltooid",
        "Completed tasks": "Voltooide taken",
        "Completed tasks overview": "Overzicht voltooide taken",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, draft is voor niemand zichtbaar",
        "Conditional Page link": "Voorwaardelijke paginalink",
        "Confirm": "Bevestig",
        "Confirm Password": "Bevestig wachtwoord",
        "Confirm password": "Wachtwoord bevestigen",
        "Consultant": "Consultant",
        "Consultation": "Consult",
        "consultation": "consult",
        "Consultations": "Consultaties",
        "Contact": "Contact",
        "Contact form": "Contactformulier",
        "Contact persoons": "Contact personen",
        "Content": "Inhoud",
        "Conversation": "Gesprek",
        "Conversations": "Gesprekken",
        "Copy from": "Kopiëren van",
        "Coupon": "Coupon",
        "Coupons": "Coupons",
        "Create": "Aanmaken",
        "create": "aanmaken",
        "Create {model}": "Maak {model}",
        "Create Account": "Account aanmaken",
        "Create and back": "Maken en terug",
        "Create a new team to collaborate with others on projects.": "Maak een nieuw team aan om met anderen aan projecten samen te werken.",
        "Create a password": "Maak een wachtwoord aan",
        "Create API Token": "Maak een API-token",
        "Create availability": "Beschikbaarheid creëren",
        "Create consultation": "Consultaat aanmaken",
        "created": "aangemaakt",
        "Created.": "Aangemaakt.",
        "Created at": "Gemaakt op",
        "Create follow-up": "Follow-up creëren",
        "Create New Team": "Maak nieuw team aan",
        "Create order": "Bestel",
        "Create Password.": "Wachtwoord aanmaken.",
        "Create question": "Vraag maken",
        "Create questionnaire": "Vragenlijst maken",
        "Create referral": "Verwijzing maken",
        "Create reply": "Antwoord maken",
        "Create subject": "Onderwerp maken",
        "Create Team": "Maak team aan",
        "currency": "valuta",
        "Current Password": "Huidig wachtwoord",
        "Current password": "Huidig wachtwoord",
        "Customers": "Klanten",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Klanten krijgen de goedkoopste toepasselijke verzendkosten van elke groep te zien. Momenteel bestaande groepen: {groups}",
        "Dangerously high": "Gevaarlijk hoog",
        "Dangerously high explanation": "Gevaarlijk hoge uitleg",
        "Dangerously Low": "Gevaarlijk laag",
        "Dangerously low": "Gevaarlijk laag",
        "Dangerously low explanation": "Gevaarlijk lage verklaring",
        "Dashboard": "Dashboard",
        "Date": "Datum",
        "Date of birth": "Geboortedatum",
        "Day": "Dag",
        "Deactivate": "Deactiveer",
        "Deactivated": "gedeactiveerd",
        "deactivated": "gedeactiveerd",
        "Dear {name}": "Beste {name}",
        "Dear user": "Beste gebruiker",
        "Default consultant": "Standaard consulent",
        "Delete": "Verwijder",
        "delete": "verwijder",
        "Delete {model}": "Verwijderen {model}",
        "Delete {name}": "Verwijderen {naam}",
        "Delete Account": "Account Verwijderen",
        "Delete API Token": "API-token Verwijderen",
        "Delete availability": "Beschikbaarheid verwijderen",
        "Delete comment": "Commentaar verwijderen",
        "deleted": "verwijderd",
        "Delete examination": "Onderzoek verwijderen",
        "Delete group": "Groep verwijderen",
        "Delete question": "Vraag verwijderen",
        "Delete reply": "Antwoord verwijderen",
        "Delete subject": "Onderwerp verwijderen",
        "Delete Team": "Team Verwijderen",
        "Delete test": "Test verwijderen",
        "Delete test metric": "Testmetriek verwijderen",
        "Department": "Afdeling",
        "department": "afdeling",
        "Departments": "Afdelingen",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Desktop": "Desktop",
        "Details": "Details",
        "Director": "Directeur",
        "Disable": "Schakel uit",
        "Disabling a coupon will prevent it from being used": "Als je een coupon uitschakelt, kan deze niet meer worden gebruikt.",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Discount code": "Kortingscode",
        "done": "klaar",
        "Done.": "Klaar.",
        "Don’t have access to your authenticator device ?": "Hebt je geen toegang tot je authenticatietoestel?",
        "Down": "Down",
        "Download app": "App downloaden",
        "Download backup codes and continue.": "Download back-up codes en ga verder.",
        "Download invoice": "Factuur downloaden",
        "Download the app": "De app downloaden",
        "Due at": "Toe doen op",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Easter monday": "Paasmaandag",
        "Easter sunday": "Paaszondag",
        "Edit": "Bewerk",
        "edit": "bewerk",
        "Edit {model}": "{model} bewerken",
        "Edit account": "Account bewerken",
        "Edit client": "Cliënt bewerken",
        "Edit Group": "Groep bewerken",
        "Editor": "Redacteur",
        "Editor users have the ability to read, create, and update.": "Redacteurs hebben de bevoegdheid om te lezen, te creëren en te bewerken.",
        "Edit Profile": "Profiel bewerken",
        "Edit user": "Gebruiker bewerken",
        "Email": "E-mailadres",
        "email": "e-mail",
        "email address": "e-mailadres",
        "Email Password Reset Link": "Verstuur link",
        "Employee": "Werknemer",
        "Employee is part of every group": "Werknemer maakt deel uit van elke groep",
        "Employee number": "Werknemersnummer",
        "Employees": "Werknemers",
        "Employers": "Werkgevers",
        "employers": "werkgevers",
        "Enable": "Schakel in",
        "End": "Einde",
        "End at": "Einde op",
        "End time": "Eindtijd",
        "Ensure your account is using a long, random password to stay secure.": "Zorg ervoor dat je account een lang, willekeurig wachtwoord gebruikt om veilig te blijven.",
        "Enter your e-mail and we send you a reset link": "Voer je e-mail in en we sturen je een resetlink",
        "Examination": "Onderzoek",
        "examination": "onderzoek",
        "Examination {name}": "Onderzoek {name}",
        "Examinations": "Onderzoeken",
        "Excel template": "Excel-sjabloon",
        "Excel update": "Excel bijwerken",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "Execute all changes": "Alle wijzigingen uitvoeren",
        "Explore our services and familiarize yourself with our platform.": "Onze diensten te verkennen en jezelf vertrouwd te maken met ons platform.",
        "Export shipping addresses": "Verzendadressen exporteren",
        "External Link": "Externe link",
        "External Test": "Externe test",
        "External Tester": "Extern testapparaat",
        "Featured Image": "Aanbevolen afbeelding",
        "Female": "Vrouw",
        "File": "Bestand",
        "Fill in the 6-digit code": "Vul de 6-cijferige code in",
        "Fill in this code in the app.": "Vul deze code in de app in.",
        "Finish enabling two factor authentication.": "Inschakelen van tweestapsverificatie afronden.",
        "First, select a department": "Selecteer eerst een afdeling",
        "First, select a practice": "Selecteer eerst een praktijk",
        "First, select a speciality": "Selecteer eerst een specialiteit",
        "First name": "Voornaam",
        "Fixed": "Vast",
        "Focal point": "Brandpunt",
        "Follow up to": "Vervolg op",
        "Follow up tot": "Opvolging tot",
        "Forbidden": "Geen toegang",
        "Forgot Password": "Wachtwoord vergeten",
        "Forgotten password?": "Wachtwoord vergeten?",
        "Forgot your password?": "Wachtwoord vergeten?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Wachtwoord vergeten? Geen probleem. Geef hier je e-mailadres in en we sturen je een link via mail waarmee je een nieuw wachtwoord kan instellen.",
        "For your security, please confirm your password to continue.": "Bevestig voor de zekerheid je wachtwoord om door te gaan.",
        "Free": "Gratis",
        "Frequently Asked Questions": "Veelgestelde vragen",
        "Frequently asked questions": "Veelgestelde vragen",
        "Friday": "Vrijdag",
        "From the authenticator app": "Vanuit de authenticator app",
        "Gallery": "Galerie",
        "Geen rol": "Geen rol",
        "Gender": "Geslacht",
        "Gender overview": "Geslachtsoverzicht",
        "General terms": "Algemene voorwaarden",
        "Good friday": "Goede vrijdag",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "Great! You have accepted the invitation to join the {team} team.": "Mooizo! Je hebt de uitnodiging om deel te nemen aan {team} geaccepteerd.",
        "Group": "Groep",
        "Group {name}": "Groep {name}",
        "Groups": "Groepen",
        "Group subdivision": "Groep onderverdeling",
        "Has a group": "Heeft een groep",
        "Heading": "Rubriek",
        "Hello": "Hallo",
        "Hello!": "Hallo!",
        "Hide on page": "Verbergen op pagina",
        "High": "Hoog",
        "Highest": "Hoogste",
        "High explanation": "Hoge uitleg",
        "Highlighted Questions": "Gemarkeerde vragen",
        "Hits": "Hits",
        "hour": "uur",
        "How much wood could a woodchuck chuck, if a woodchuck could chuck wood?": "Hoeveel hout zou een bosmarmot kunnen sprokkelen, als een bosmarmot hout kon sprokkelen?",
        "I agree to the {terms_of_service} and {privacy_policy}": "Ik ga akkoord met de {terms_of_service} en de {privacy_policy}",
        "Icon": "Pictogram",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If left empty, the page title will be used.": "Als deze leeg blijft, wordt de paginatitel gebruikt.",
        "If left empty, the title will be used.": "Als deze leeg blijft, wordt de titel gebruikt.",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "Indien nodig kun je uitloggen bij al je andere browsersessies op al je apparaten. Sommige van je recente sessies staan hieronder vermeld; deze lijst is echter mogelijk niet volledig. Als je vermoedt dat je account is gecompromitteerd, wijzig dan ook je wachtwoord.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you already have an account, you may accept this invitation by clicking the button below:": "Als je al een account hebt, kan je deze uitnodiging accepteren door op onderstaande knop te klikken:",
        "If you are still not receiving login details, please contact us.": "Als u nog steeds geen inloggegevens ontvangt, neem dan contact met ons op.",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "Als je geen uitnodiging voor dit team verwachtte, mag je deze mail negeren.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "Als je nog geen account hebt, kan je er een aanmaken door op onderstaande knop te klikken. Na het aanmaken van je account kan je op de uitnodiging in deze mail klikken om die te accepteren:",
        "If you encounter any issues or have any questions, our dedicated support team is just an email {supportMail} or phone {supportPhone} call away. Please don’t hesitate to reach out.": "Mocht u problemen ondervinden of vragen hebben, ons toegewijde ondersteuningsteam is slechts een e-mail {supportMail} of telefoontje {supportPhone} verwijderd. Aarzel niet om contact op te nemen.",
        "If you wish to use our services again, you can create a new account. Unfortunately, you cannot view the results of deleted accounts with a new account.": "Wanneer u weer gebruik wenst te maken van onze diensten kunt u een nieuw account aanmaken. U kunt helaas niet resultaten van verwijderde accounts zien met een nieuw account.",
        "I have saved the backup codes": "Ik heb de back-up codes opgeslagen",
        "Image": "Afbeelding",
        "Including VAT": "Inclusief BTW",
        "Index Page": "Indexpagina",
        "Information": "Informatie",
        "Initial": "Initieel",
        "Inloggen": "Inloggen",
        "Input label": "Label invoer",
        "Inspection": "Keuring",
        "inspection": "keuring",
        "Inspections": "Keuringen",
        "Instruction title": "Titel van de instructie",
        "Internal comment": "Interne opmerking",
        "Internal comments": "Interne opmerkingen",
        "Introduction": "Inleiding",
        "Invalid": "Ongeldig",
        "Invalid JSON was returned from the route.": "Er is ongeldige JSON teruggekomen van de route.",
        "Invalid phone number": "Ongeldig telefoonnummer",
        "Is the link no longer valid? Then click on": "Is de link niet meer geldig? Klik dan op",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribute} tussen {min} en {max} tekens lang te houden.",
        "Je winkelmand is leeg": "Je winkelmand is leeg",
        "Kind regards,": "Met vriendelijke groet,",
        "Kind regards, <br> {appName}": "Met vriendelijke groet, <br> {appName}",
        "KVK number": "KVK-nummer",
        "Label": "Label",
        "Label Button": "Labelknop",
        "Laboratory": "Laboratorium",
        "Last active": "Laatst actief",
        "Last message on": "Laatste bericht op",
        "Last name": "Achternaam",
        "Last refresh was {quantity} {unit} ago": "Laaste verversing was {quantity} {unit} geleden",
        "Last used": "Laatst gebruikt",
        "Leave": "Verlaat",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to generate a random code. Not editable after creation.": "Leeg laten om een willekeurige code te genereren. Kan niet worden bewerkt na aanmaak.",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Leave Team": "Team Verlaten",
        "Link": "Link",
        "Link {model}": "Link {model}",
        "Link Button": "Koppelingsknop",
        "Link inspection to other examination": "Inspectie koppelen aan ander onderzoek",
        "List": "Lijst",
        "Location": "Locatie",
        "location": "locatie",
        "Locations": "Locaties",
        "Log in": "Inloggen",
        "Login": "Inloggen",
        "Login with a recovery code.": "Aanmelden met een herstelcode.",
        "Log in with your provided credentials.": "In te loggen met je verstrekte inloggegevens.",
        "Log Out": "Uitloggen",
        "Logout": "Uitloggen",
        "Log Out Other Browser Sessions": "Uitloggen bij alle sessies",
        "Low": "Laag",
        "Lowest": "Laagste",
        "Low explanation": "Lage uitleg",
        "Made by:": "Gerealiseerd door:",
        "Male": "Man",
        "Manage Account": "Accountbeheer",
        "Manage and log out your active sessions on other browsers and devices.": "Beheer je actieve sessies op andere browsers en andere apparaten.",
        "Manage API Tokens": "Beheer API-tokens",
        "Manager": "Manager",
        "Manage Role": "Beheer Rol",
        "Managers": "Managers",
        "Manage Team": "Beheer Team",
        "Manage the company subscriptions": "De bedrijfsabonnementen beheren",
        "Manual intervention is needed": "Handmatige tussenkomst is nodig",
        "Mark as done": "Markeren als gedaan",
        "Max users": "Maximaal aantal gebruikers",
        "Media": "Media",
        "Medical": "Medisch",
        "Medium": "Medium",
        "Menu items": "Menu-items",
        "Message": "Bericht",
        "Messages": "Berichten",
        "Metric Name": "Meetwaarden naam",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimaal bestelbedrag",
        "minute": "minuut",
        "minutes": "minuten",
        "Mobile": "Mobiel",
        "Monday": "Maandag",
        "More information": "Meer informatie",
        "More settings can be set in the Page Block Settings page": "Meer instellingen kunnen worden ingesteld op de pagina Instellingen Paginablokkering",
        "moved": "verplaatst",
        "Multiple choice": "Meerdere keuzes",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "My groups": "Mijn groepen",
        "Name": "Naam",
        "name": "naam",
        "Name client": "Naam cliënt",
        "Needs Manual Intervention": "Handmatige interventie nodig",
        "Netherlands": "Nederland",
        "New consultation": "Nieuw consultaat",
        "New Password": "Nieuw wachtwoord",
        "New password": "Nieuw wachtwoord",
        "New question": "Nieuwe vraag",
        "New reply": "Nieuw antwoord",
        "New subject": "Nieuw onderwerp",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "No": "Nee",
        "No {models} left to add": "Geen {models} over om toe te voegen",
        "No data available": "Geen gegevens beschikbaar",
        "No items": "Geen items",
        "No location": "Geen locatie",
        "No manager": "Geen manager",
        "None": "Geen",
        "No open conversations, a new conversation will be created": "Geen open gesprekken, er wordt een nieuw gesprek aangemaakt",
        "No other employees available": "Geen andere medewerkers beschikbaar",
        "No other subscriptions available": "Geen andere abonnementen beschikbaar",
        "No other suitable examinations": "Geen andere geschikte onderzoeken",
        "No parent group": "Geen hoofd groep",
        "No practitioners left to add": "Geen werknemers meer om toe te voegen",
        "No results": "Geen resultaten",
        "Normal": "Normaal",
        "Normal explanation": "Normale uitleg",
        "No subscription left to add": "Geen abonnement meer om toe te voegen",
        "Not editable after creation.": "Niet bewerkbaar na aanmaak.",
        "Not Found": "Niet gevonden",
        "Not implemented yet": "Nog niet geïmplementeerd",
        "Number": "Volgnummer",
        "Number of customers": "Aantal klanten",
        "Number of questions": "Aantal vragen",
        "Number of specialists": "Aantal specialisten",
        "Number of test": "Aantal testen",
        "of": "van",
        "Old URL": "Oude URL",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Zodra een team is verwijderd, worden alle bronnen en gegevens permanent verwijderd. Download voordat je dit team verwijdert alle gegevens of informatie over dit team die je wilt behouden.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Sla alsjeblieft alle data op die je wilt behouden, voordat je je account verwijderd.",
        "Onderwerpen": "Onderwerpen",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "Op je telefoon of tablet met camera. We kunnen je aanraden om {google} of {microsoft} te gebruiken.",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.\":\"Op je telefoon of tablet met camera. Wij kunnen he adviseren om {google} of {microsoft} te gebruiken.\",": "Op he telefoon of tablet met camera. Wij kunnen adviseren om {google} of {microsoft} te gebruiken.\":\"Op je telefoon of tablet met camera. Wij kunnen je adviseren om {google} of {microsoft} te gebruiken.\",",
        "Open app": "App openen",
        "Open ended": "Open einde",
        "Open ended with choice": "Open einde met keuze",
        "Openings hours": "Openingstijden",
        "Open in new tab": "In nieuw tabblad openen",
        "Open the authenticator app, add a new entry and scan the QR code": "Open de authenticator-app, voeg een nieuw item toe en scan de QR-code",
        "or": "of",
        "Orange": "Oranje",
        "Orange Button": "Oranje knop",
        "Order": "Bestel",
        "Orders": "Bestellingen",
        "Overview": "Overzicht",
        "Page": "Pagina",
        "Page blocks": "Pagina blokken",
        "Page Content": "Pagina-inhoud",
        "Page Expired": "Pagina niet meer geldig",
        "Page link": "Pagina link",
        "Pages": "Pagina's",
        "Page Title": "Pagina Titel",
        "Pagination Navigation": "Paginanavigatie",
        "Paragraph": "Paragraaf",
        "Parent category": "Hoofdcategorie",
        "Parent group": "Hoofdgroep",
        "Password": "Wachtwoord",
        "password": "wachtwoord",
        "Paste or type the code we sent a code to your e-mail.": "Plak of typ de code die we naar je e-mail hebben gestuurd.",
        "Paste or type the code we sent a code to your phone number.": "Plak of typ de code die we naar je telefoonnummer hebben gestuurd.",
        "Payment Required": "Betaling Vereist",
        "Pay safe": "Veilig betalen",
        "Pending scheduling": "In afwachting van planning",
        "Pending Team Invitations": "Openstaande Team uitnodigingen",
        "Pentecost": "Pinksteren",
        "Pentecost monday": "Pinkstermaandag",
        "Percentage": "Percentage",
        "Perform your first health check at your convenience.": "Je eerste gezondheidscontrole uit te voeren wanneer het jouw uitkomt.",
        "Permanent": "Permanent",
        "Permanently delete this team.": "Verwijder dit team definitief.",
        "Permanently delete your account.": "Verwijder je account permanent.",
        "Permissions": "Rechten",
        "Personal accounts with subscriptions": "Persoonlijke accounts met abonnementen",
        "Phone": "Telefoon",
        "Phone Number": "Telefoonnummer",
        "Phone number": "Telefoonnummer",
        "phone number": "telefoonnummer",
        "phone_number": "telefoonnummer",
        "Photo": "Foto",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Bevestig de toegang tot je account door een van je noodherstelcodes in te voeren.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Bevestig de toegang tot je account door de authenticatiecode in te voeren die door je authenticator-applicatie is aangemaakt.",
        "Please copy your new API token. For your security, it won't be shown again.": "Kopieer je nieuwe API-token. Voor de veiligheid zal het niet opnieuw getoond worden.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Voer je wachtwoord in om te bevestigen dat je je wilt afmelden bij je andere browsersessies op al je apparaten.",
        "Please provide the email address of the person you would like to add to this team.": "Geef het e-mailadres op van de persoon die je aan dit team wilt toevoegen.",
        "Please submit manual changes individually": "Dien handmatige wijzigingen afzonderlijk in",
        "Please use the mobile app. This web app is for staff only": "Gebruik de mobiele app. Deze web app is alleen voor medewerkers",
        "Postal code": "Postcode",
        "Postcode": "Postcode",
        "Practice": "Praktijk",
        "practice": "praktijk",
        "Practices": "Praktijken",
        "Practitioner": "Werknemer",
        "practitioner": "werknemer",
        "Price": "Prijs",
        "Print": "Afdrukken",
        "Priority": "Prioriteit",
        "Privacy declaration": "Privacyverklaring",
        "Privacy page": "Privacy pagina",
        "Privacy Policy": "Privacyverklaring",
        "Privacy policy": "Privacyverklaring",
        "Privacy statement": "Privacyverklaring",
        "Product": "Product",
        "Products": "Producten",
        "Product Settings": "Productinstellingen",
        "Profile": "Profiel",
        "Profile Information": "Profiel Informatie",
        "Provider": "Zorgverlener",
        "provider": "zorgverlener",
        "Providers": "Zorgverleners",
        "Published At": "Gepubliceerd op",
        "Purple": "Paars",
        "QR code": "QR-code",
        "Quantity": "Hoeveelheid",
        "Question": "Vraag",
        "Questionnaire": "Vragenlijst",
        "questionnaire": "vragenlijst",
        "Questionnaire Overview": "Overzicht vragenlijst",
        "Questionnaires": "Vragenlijsten",
        "Questions": "Vragen",
        "Questions for {subjectName}": "Vragen voor {subjectName}",
        "Questions using {testMetricName}": "Vragen met {testMetricName}",
        "Reactivate": "Reactiveren",
        "Read more": "Meer lezen",
        "Realized by:": "Gerealiseerd door:",
        "Reason for cancellation": "Reden voor annulering",
        "Received by lab": "Ontvangen door lab",
        "Recovery Code": "Herstelcode",
        "Referral": "Verwijzing",
        "referral": "verwijzing",
        "Referral letter": "Verwijsbrief",
        "Referrals": "Verwijzingen",
        "Regards": "Met vriendelijke groet",
        "Regenerate Recovery Codes": "Herstelcodes Opnieuw Genereren",
        "Register": "Registreren",
        "Reimbursable treatments": "Vergoedbare behandelingen",
        "Reject all changes": "Alle wijzigingen afwijzen",
        "Reject cookies": "Cookies weigeren",
        "Related blog": "Verwante blog",
        "Related products": "Verwante producten",
        "Relink": "Relink",
        "Remember me": "Onthouden",
        "Remove": "Verwijder",
        "removed from group": "verwijderd uit de groep",
        "Remove discount code": "Kortingscode verwijderen",
        "Remove employee": "Werknemer verwijderen",
        "Remove Photo": "Foto Verwijderen",
        "Remove Team Member": "Teamlid Verwijderen",
        "Reopened subtask": "Heropende subtaak",
        "Reply": "Antwoord",
        "Request access to client information": "Toegang vragen tot klantgegevens",
        "Request report": "Rapport aanvragen",
        "Resend": "Opnieuw versturen",
        "Resend {attribute}": "Verstuur {attribute} opnieuw",
        "Resend in": "Opnieuw verzenden in",
        "Resend Verification Email": "Verificatie-e-mail opnieuw versturen",
        "Reserved By": "Gereserveerd door",
        "Reset Password": "Wachtwoord herstellen",
        "Reset password": "Wachtwoord opnieuw instellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Results": "Resultaten",
        "results": "resultaten",
        "Results {testName} are shared": "Resultaten {testName} worden gedeeld",
        "Results {testName} are shared by {name}": "Resultaten {testName} worden gedeeld door {name}",
        "Results {testName} shared by {name}": "Resultaten {testName} gedeeld door {name}",
        "Results are shared": "De resultaten worden gedeeld",
        "Results received": "Resultaten ontvangen",
        "Reviews": "Beoordelingen",
        "Rol": "Rol",
        "Role": "Rol",
        "role": "rol",
        "Roles": "Rollen",
        "Sampled": "Gesampled",
        "Sampled at": "Afgenomen op",
        "Saturday": "Zaterdag",
        "Save": "Opslaan",
        "Save and back": "Opslaan en terug",
        "Saved.": "Opgeslagen.",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Bewaar deze codes op een veilige plek als je geen toegang krijgt met je Authenticator app.",
        "Scanning not possible?": "Scannen niet mogelijk?",
        "Scanning not possible? Fill in this code in the app.": "Scannen niet mogelijk? Vul deze code in de app in.",
        "Scan the QR code": "Scan de QR-code",
        "Scheduled": "Gepland",
        "Scope": "Toepassingsgebied",
        "Score": "Score",
        "Search": "Zoek op",
        "Search by {attribute}": "Zoeken op {attribute}",
        "Search by client": "Zoeken op client",
        "Search for your question": "Zoek je vraag",
        "sec": "sec",
        "Select a {model}": "Selecteer een {model}",
        "Select a {model} to add them": "Selecteer een {model} om deze toe te voegen",
        "Select a client": "Selecteer een klant",
        "Select a conversation to add": "Selecteer een gesprek om toe te voegen",
        "Select a department": "Selecteer een afdeling",
        "Select a employee": "Selecteer een werknemer",
        "Select an consultant": "Selecteer een consultant",
        "Select A New Photo": "Selecteer Een Nieuwe Foto",
        "Select an practitioner": "Selecteer een werknemer",
        "Select an practitioner to add them": "Selecteer een werknemer om deze toe te voegen",
        "Select a practice": "Kies een praktijk",
        "Select a provider": "Selecteer een zorgverlener",
        "Select a questionnaire to use as a template": "Selecteer een vragenlijst om als sjabloon te gebruiken",
        "Select a role": "Selecteer een rol",
        "Select a speciality": "Selecteer een specialiteit",
        "Select a status": "Selecteer een status",
        "Select a subject": "Selecteer een onderwerp",
        "Select a subscription": "Selecteer een abonnement",
        "Select a template to add the corresponding blocks.": "Selecteer een sjabloon om de bijbehorende blokken toe te voegen.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Select a test": "Selecteer een test",
        "Select a test metric": "Selecteer een meetwaarden",
        "Select a time slot": "Selecteer een tijdslot",
        "Select a type": "Selecteer een type",
        "Select company": "Selecteer bedrijf",
        "Select examination": "Selecteer examen",
        "Select group to add": "Groep selecteren om toe te voegen",
        "Select subscription": "Selecteer abonnement",
        "Send": "Stuur",
        "Send e-mail": "Stuur e-mail",
        "Send verification {attribute}": "Verificatie {attribute} versturen",
        "Seo Settings": "Seo-instellingen",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Sequence number": "Volgnummer",
        "sequence number": "volgnummer",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Set  password": "Wachtwoord instellen",
        "Sets": "Zet",
        "Set Two Factor Authentication": "Twee Factor Authenticatie instellen",
        "Setup Key": "Setup Sleutel",
        "Set your password": "Stel je wachtwoord in",
        "Set your secure password below": "Stel hieronder je veilige wachtwoord in",
        "Share {testName} results": "Resultaten delen {testName}",
        "Share blog": "Deel blog",
        "shared": "gedeeld",
        "Shared with client": "Gedeeld met client",
        "Share results": "Resultaten delen",
        "Shipping address": "Verzendadres",
        "Shipping address same as billing address": "Verzendadres hetzelfde als factuuradres",
        "Shipping method": "Verzendmethode",
        "Shipping Rate": "Verzendtarief",
        "Shipping Rates": "Verzendtarieven",
        "Shop": "Winkel",
        "Shopping cart": "Winkelwagen",
        "shopping cart": "winkelwagen",
        "Shopping cart page": "Pagina met winkelwagentje",
        "Should you have any questions, please contact us at": "Als je vragen hebt, kun je contact met ons opnemen via",
        "Showing": "Toont",
        "Show Recovery Codes": "Toon herstelcodes",
        "Sign out": "Afmelden",
        "Similar inspections on the target examination will be deleted": "Gelijkaardige inspecties op het doelonderzoek worden verwijderd",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Omdat het de eerste keer is dat je hier inlogt, hebben we een code naar je telefoon gestuurd om je gebruikersprofiel te verifiëren.",
        "Single choice": "Enkele keuze",
        "Slug": "Permalink",
        "Specialities": "Specialiteiten",
        "Speciality": "Specialiteit",
        "speciality": "specialiteit",
        "Specialty": "Specialiteit",
        "Start": "Start",
        "Start conversation": "Gesprek beginnen",
        "Started at": "Gestart op",
        "Starting": "Start",
        "Starting after": "Vanaf",
        "Starting from": "Vanaf",
        "Starts at": "Begint bij",
        "Start time": "Starttijd",
        "Status": "Status",
        "Statuses": "Statussen",
        "statuses": "Statussen",
        "Step": "Stap",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Bewaar deze herstelcodes in een beveiligde wachtwoordbeheerder. Ze kunnen worden gebruikt om de toegang tot je account te herstellen als je tweestapsverificatie verloren is gegaan.",
        "Street name": "Straatnaam",
        "Street number": "Huisnummer",
        "Street number addition": "Toevoeging",
        "Strixi": "Strixi",
        "Subject": "Onderwerp",
        "Subjects of {model}": "Onderwerpen van {model}",
        "Sub menu": "Submenu",
        "Subscription": "Abonnement",
        "subscription": "abonnement",
        "Subscriptions": "Abonnementen",
        "SubTasks": "Subtaken",
        "Subtotal": "Subtotaal",
        "Sunday": "Zondag",
        "Switch Teams": "Wissel Van Team",
        "Switch to named day": "Overschakelen naar dag met naam",
        "Switch to specific date": "Overschakelen naar specifieke datum",
        "Task": "Taak",
        "Tasks": "Taken",
        "Team Details": "Teamdetails",
        "Team Invitation": "Team uitnodiging",
        "Team Members": "Teamleden",
        "Team Name": "Teamnaam",
        "Team Owner": "Team Eigenaar",
        "Team Settings": "Team Instellingen",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Terms of Service": "Algemene voorwaarden",
        "Terms page": "Voorwaarden pagina",
        "Test": "Test",
        "test": "test",
        "Test metric": "Test meetwaarden",
        "Test metric of {model}": "Testmetriek van {model}",
        "Test metrics": "Testgegevens",
        "Test name": "Naam test",
        "Tests": "Testen",
        "Test Set": "Testreeks",
        "Test set": "Testreeks",
        "test set": "testreeks",
        "Testset name": "Naam testset",
        "Test sets": "Testreeksen",
        "Tests taken": "Afgenomen testen",
        "Text": "Tekst",
        "Thank you! We will keep you informed!": "Hartelijk dank! We houden je op de hoogte!",
        "Thank you for your message.": "Bedankt voor je bericht.",
        "Thank you page settings": "Instellingen bedankpagina",
        "The {attribute} has already been taken.": "Het {attribute} is al ingenomen.",
        "The {attribute} must be at least {length} characters.": "Het {attribute} moet minstens {length} karakters lang zijn.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minstens één speciaal karakter bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "Het {attribute} moet minstens {length} karakters zijn en minstens één speciaal teken en één nummer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter, één cijfer en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter en één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter en één speciaal teken bevatten.",
        "The {attribute} must be a valid role.": "Het {attribute} moet een geldige rol zijn.",
        "The {attribute} must be verified.": "Het {attribute} moet worden geverifieerd.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The accounts which have access to this company": "De accounts die toegang hebben tot dit bedrijf",
        "The admin {name} has viewed the medical results in task {number}.": "De beheerder {name} heeft de medische resultaten in taak {number} bekeken.",
        "The conversation is closed.": "Het gesprek is gesloten.",
        "The coupon has already been used.": "De coupon is al gebruikt.",
        "The coupon has expired.": "De coupon is verlopen.",
        "The file is missing the \"{header}\" column": "De kolom \"{header}\" ontbreekt in het bestand",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The lab results for the inspection with barcode {barcode} (test: {test}) needs manual intervention.": "Voor de labresultaten voor de inspectie met barcode {barcode} (test: {test}) is handmatige interventie nodig.",
        "The message from the lab is: {message}": "Het bericht van het lab is: {message}",
        "The password is incorrect.": "Het wachtwoord is incorrect.",
        "The provided {attribute} is invalid.": "De verstrekte {attribute} is ongeldig.",
        "The provided code was invalid.": "De verstrekte code was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met je huidige wachtwoord.",
        "The provided password was incorrect.": "Het opgegeven wachtwoord is onjuist.",
        "The provided two factor authentication code was invalid.": "De opgegeven tweestapsverificatie was ongeldig.",
        "The provided two factor recovery code was invalid.": "De gegeven twee-staps herstelcode was ongeldig.",
        "The response is not a streamed response.": "De respons is niet gestreamd.",
        "The response is not a view.": "De respons is geen view.",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "Deze personen hebben een uitnodiging ontvangen om lid te worden van je team. Ze kunnen deelnemen door de uitnodiging te accepteren.",
        "The team's name and owner information.": "De naam van het team en de informatie over de eigenaar.",
        "The tests cannot be changed once the test set is created.": "De tests kunnen niet worden gewijzigd nadat de testreeks is aangemaakt.",
        "This {attribute} has already been used": "Dit {attribute} is al gebruikt",
        "This area is restricted to company members only.": "Dit gebied is alleen toegankelijk voor bedrijfsleden.",
        "This area is restricted to medical medic members only.": "Dit gebied is alleen toegankelijk voor medische leden.",
        "This area is restricted to provider members only.": "Dit gebied is alleen toegankelijk voor leden van de provider.",
        "This area is restricted to staff members only.": "Dit gebied is alleen toegankelijk voor personeelsleden.",
        "This device": "Dit apparaat",
        "This is a secure area of the application. Please confirm your password before continuing.": "Dit is een beveiligd gedeelte van de applicatie. Bevestig je wachtwoord voordat je doorgaat.",
        "This is the label that will be shown to the user when they are asked to input a value.": "Dit is het label dat aan de gebruiker wordt getoond wanneer deze wordt gevraagd een waarde in te voeren.",
        "This is your first login": "Dit is je eerste login",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This password does not match our records.": "Het wachtwoord is onbekend.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This task is reserved by {userName}": "Deze task is gereserveerd door {userName}",
        "This task is reserved by {userName}.": "Deze task is gereserveerd door {userName}.",
        "This user already belongs to the team.": "Deze gebruiker is al toegewezen aan het team.",
        "This user has already been invited to the team.": "Deze gebruiker is al uitgenodigd voor het team.",
        "Thursday": "Donderdag",
        "Time": "Tijd",
        "Time reference": "Tijdreferentie",
        "Time slot": "Tijdslot",
        "Time slot {startDateTime} - {endTime}": "Tijdslot {startDateTime} - {endTime}",
        "Time slots": "Tijdslots",
        "Times used": "Gebruikte tijden",
        "Tip": "Tip",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "TODO": "TODO",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "Scan de volgende QR-code met de auhenticatie-applicatie van je telefoon of gebruik de setup-sleutel en voer de gegenereerde OTP-code in om het inschakelen van tweestapsverificatie af te ronden.",
        "Toggle navigation": "Schakel navigatie",
        "token": "token",
        "Token Name": "Token Naam",
        "Too Many Requests": "Te veel serververzoeken",
        "Total": "Totaal",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "Om Topchefs te gebruiken is Two Factor Authentication nodig als extra beveiliging, u kunt dit nu instellen",
        "Trans": "Trans",
        "Tuesday": "Dinsdag",
        "Two-factor Confirmation": "Bevestiging met twee factoren",
        "Two Factor Authentication": "Tweestapsverificatie",
        "Two Factor Authentication - Backup codes": "Twee Factor Authenticatie - Back-up codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Tweestapsverificatie is nu ingeschakeld. Scan de volgende QR-code met de authenticatie-applicatie van je telefoon of gebruik de setup-sleutel.",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is vereist als extra beveiliging, dit kun je nu instellen",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "Unknown": "Onbekend",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Up": "Omhoog",
        "Update": "Bijwerken",
        "Update {model}": "Bijwerken {model}",
        "Update and back": "Update en terug",
        "Update comment": "Commentaar bijwerken",
        "Update consultation": "Consultaat bijwerken",
        "updated": "bijgewerkt",
        "Update Password": "Wachtwoord Aanpassen",
        "Update password": "Wachtwoord bijwerken",
        "Update your account's profile information and email address.": "Pas je profiel informatie en e-mailadres aan.",
        "Upload": "Uploaden",
        "upload": "uploaden",
        "Url": "Url",
        "url": "url",
        "Use an authentication code": "Gebruik een autorisatiecode",
        "Use a recovery code": "Gebruik een herstelcode",
        "User": "Gebruiker",
        "user": "gebruiker",
        "Users": "Gebruikers",
        "users": "gebruikers",
        "Use `-1` for unlimited usage": "Gebruik `-1` voor onbeperkt gebruik",
        "USPs": "USP's",
        "Uw winkelmand is leeg": "Uw inkelmand is leeg",
        "Value": "Waarde",
        "Variant": "Variant",
        "Variants": "Varianten",
        "VAT": "BTW",
        "Verify": "Controleer",
        "Verify {attribute}": "Verifieer {attribute}",
        "Verify Email Address": "Verifieer e-mailadres",
        "Verify your phone number": "Controleer je telefoonnummer",
        "Verwijderen": "Verwijderen",
        "View": "Bekijk",
        "view": "bekijk",
        "View all": "Bekijk alles",
        "view all": "bekijk alles",
        "Waiting for payment conformation": "Wachten op betalingsbevestiging",
        "Waiting for tasks": "Wachten op taken",
        "Was unable to find an address with this postcode and street number": "Kon geen adres vinden met deze postcode en straatnummer",
        "We are delighted to welcome you to {link}! As a valued new client, you are now part of a community committed to enhancing vitality and well-being.": "We zijn verheugd u te verwelkomen bij {link}! Als gewaardeerde nieuwe klant bent u nu onderdeel van een gemeenschap die zich inzet voor het verhogen van vitaliteit en welzijn.",
        "We are excited to be a part of your journey towards a healthier life. Thank you for choosing {link}, and we look forward to supporting you.": "We zijn enthousiast om deel uit te maken van je reis naar een gezonder leven. Dank u voor het kiezen van {link}, en we kijken ernaar uit om u te ondersteunen.",
        "Web Page": "Website",
        "Web Page Settings": "Instellingen webpagina",
        "Wednesday": "Woensdag",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We hereby confirm that your account has been deleted from our platform. This is also the last email you will receive from us.": "Bij deze bevestigen we dat uw account is verwijderd van ons platform, dit is dan ook de laatste email die u van ons heeft gekregen.",
        "Welcome": "Welkom",
        "Welcome aboard the {link} team! We are thrilled that you are joining our dynamic and dedicated group of professionals.": "Welkom aan boord van het {link} team! We zijn verheugd dat je je aansluit bij onze dynamische en toegewijde groep professionals.",
        "Welcome aboard the Hoegezondbenik.nu team!": "Welkom aan boord van het Hoegezondbenik.nu team!",
        "Welcome to {link} - Your Journey to Well-being Begins!": "Welkom bij {link} - Jouw Reis naar Welzijn Begint!",
        "Welcome to {page}": "Welkom op {page}",
        "Welcome to Hoegezondbenik.nu - Your Journey to Well-being Begins!": "Welkom bij Hoegezondbenik.nu - Jouw Reis naar Welzijn Begint!",
        "We were unable to find a registered user with this email address.": "Er is geen gebruiker met dit e-mailadres.",
        "When assigning a qr code already in use, the previous test set will be unassigned.": "Bij het toewijzen van een qr-code die al in gebruik is, word de huidige testset ontkoppeld.",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "Als tweestapsverificatie is ingeschakeld, word je tijdens de authenticatie om een veilige, willekeurige token gevraagd. Je kunt dit token ophalen uit de Google Authenticator-applicatie op je telefoon.",
        "Whoops!": "Oeps!",
        "Whoops! Something went wrong.": "Oeps! Er is iets misgelopen.",
        "Yes": "Ja",
        "You are muted.": "Je bent gedempt.",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You can contact us via the form. We look forward to hearing from you. Have you looked at the frequently asked questions? With the exception of weekends and holidays, we try to send you a response to your questions within 24 hours.": "U kunt contact met ons opnemen via het formulier. We horen graag van u. Heeft u gekeken naar de veelgestelde vragen? Met uitzondering van weekenden en feestdagen proberen we binnen 24 uur een reactie op je vragen te sturen.",
        "You have been invited to join the {team} team!": "Je bent uitgenodigd om lid te worden van team {team}!",
        "You have enabled two factor authentication.": "Je hebt tweestapsverificatie ingeschakeld.",
        "You have not enabled two factor authentication.": "Je hebt tweestapsverificatie niet ingeschakeld.",
        "You may accept this invitation by clicking the button below:": "Je kunt de uitnodiging accepteren door op de volgende knop te klikken:",
        "You may delete any of your existing tokens if they are no longer needed.": "Je kunt al je bestaande tokens verwijderen als ze niet langer nodig zijn.",
        "You may not delete your personal team.": "Je mag je persoonlijke team niet verwijderen.",
        "You may not leave a team that you created.": "Je kan het team dat je aangemaakt hebt niet verlaten.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your account has been activated, and you can now access our range of services and resources. To get started, we encourage you to:": "Je account is geactiveerd en je kunt nu toegang krijgen tot ons scala aan diensten en bronnen. Om te beginnen, moedigen we u aan om:",
        "Your account is inactive. Please contact support.": "Je account is inactief. Neem contact op met support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Je account is inactief. Neem contact op met support. Om de website als gast te gebruiken, vernieuw je de pagina.",
        "Your account is now active. You can log in and immediately gain access to all the necessary tools and resources. We encourage you to familiarize yourself with our system and the various aspects of your new role.": "Je account is nu actief. Je kunt inloggen en direct toegang krijgen tot alle benodigde hulpmiddelen en bronnen. We moedigen je aan om vertrouwd te raken met ons systeem en de diverse aspecten van je nieuwe rol.",
        "Your current locale is {locale}": "Je huidige locale is {locale}",
        "Your email address is unverified.": "Je e-mailadres is niet geverifieerd.",
        "Your experience on this site is enhanced by the use of cookies.": "Je ervaring op deze site wordt verbeterd door het gebruik van cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Je laatste login was {relativeTime} op {dateTime}",
        "Your Order": "Je bestelling",
        "Your order": "Je bestelling",
        "Your password has expired, please reset your password.": "Je wachtwoord is verlopen, stel je wachtwoord opnieuw in.",
        "Your password has expired. Please reset your password to continue.": "Je wachtwoord is verlopen. Reset je wachtwoord om verder te gaan.",
        "Your Profile": "Je profiel",
        "Your shopping cart is empty": "Je winkelwagen is leeg",
        "Your verification code for {appName}": "Je verificatiecode voor {appName}",
        "Your verification code for {appName}.": "Je verificatiecode voor {appName}.",
        "Your verification code for {appName} is {code}.": "Je verificatiecode voor {appName} is {code}.",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "auth": {
            "failed": "Deze combinatie van gegevens is niet geldig of verlopen.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "blog": "blog",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "products": "producten",
            "privacy-policy": "privacy-beleid",
            "cookie-policy": "cookie-beleid",
            "contact": "contact",
            "search": "zoeken"
        },
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "can": "{Attribute} bevat een waarde waar je niet bevoegd voor bent.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "hex_color": "{Attribute} moet een geldige hexadecimale kleurcode zijn.",
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "present_if": "{Attribute} moet aanwezig zijn als {other} {value} is.",
            "present_unless": "{Attribute} moet aanwezig zijn tenzij {other} {value} is.",
            "present_with": "{Attribute} moet aanwezig zijn als {values} aanwezig is.",
            "present_with_all": "{Attribute} moet aanwezig zijn als {values} aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "billing_address.city": "Plaats",
                "billing_address.company_name": "Bedrijfsnaam",
                "billing_address.country": "Land",
                "billing_address.postcode": "Postcode",
                "billing_address.street_name": "Straatnaam",
                "billing_address.street_number": "Huisnummer",
                "billing_address.street_number_addition": "Toevoeging",
                "billing_address.vat_number": "BTW nummer",
                "birthday": "verjaardag",
                "body": "lichaam",
                "city": "stad",
                "consultant_id": "Consultant",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "employee_number": "Werknemersnummer",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "role_id": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "shipping_address.city": "Plaats",
                "shipping_address.postcode": "Postcode",
                "shipping_address.street_name": "Straatnaam",
                "shipping_address.street_number": "Huisnummer",
                "shipping_address.street_number_addition": "Toevoeging",
                "shipping_rate_id": "Verzendmethode",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "leerling",
                "subject": "onderwerp",
                "subscription_id": "Abonnement",
                "teacher": "docent",
                "terms": "voorwaarden",
                "terms_and_conditions": "Algemene voorwaarden en privacy verklaring",
                "test_description": "test omschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "user_id": "gebruiker",
                "user_type": "gebruikerstype",
                "username": "gebruikersnaam",
                "year": "jaar",
                "test_ids": "testen",
                "test_ids.*": "test",
                "product_id": "product",
                "test_set_id": "testset"
            }
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met dit e-mailadres."
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        }
    }
}
